<template>
  <Guard permission="users.edit" v-slot="{ isAvailable }">
    <div class="edit-user-page">
      <BackTitle class="mb-40" @click="goBack">
        {{ title }}
      </BackTitle>
      <Loader v-if="isLoading" class="mx-auto" color="primary" size="m" />
      <template v-else-if="user">
        <Avatar
          :url="user.photoUrl"
          :text="user.displayName"
          is-vertical
          size="large"
          class="mb-40 mx-auto"
        />
        <div class="edit-user-page__info">
          <template v-if="isYou || !isAvailable">
            <div class="edit-user-page__info__row">
              <span>Email</span>
              <span>{{ user.email || "-" }}</span>
            </div>
            <div class="edit-user-page__info__row">
              <span>Phone number</span>
              <span>{{ user.phoneNumber || "-" }}</span>
            </div>
            <div class="edit-user-page__info__row">
              <span>Role</span>
              <span>{{ getRoleName(user.role) || "-" }}</span>
            </div>
          </template>
          <div class="edit-user-page__info__row">
            <span>Last seen at</span>
            <span>{{ getLastTimeString(user.lastSeenTimestamp) }}</span>
          </div>
          <div class="edit-user-page__info__row">
            <span>Last login at</span>
            <span>{{ getLastTimeString(user.lastLoginTimestamp) }}</span>
          </div>
        </div>
        <Form
          v-if="!isYou && isAvailable"
          v-slot="{ invalid, changed }"
          class="edit-user-page__form"
        >
          <div class="grid-2 mb-32">
            <FormItem rules="required" v-slot="{ isError }">
              <Select
                v-model="form.role"
                :options="roleOptions"
                label="Role"
                :is-error="isError"
                :is-disabled="!isAvailable"
              />
            </FormItem>
            <FormItem rules="required" v-slot="{ isError }">
              <Input
                v-model="form.email"
                label="Email"
                placeholder="Email"
                :is-error="isError"
                :is-disabled="!isAvailable"
              />
            </FormItem>
            <FormItem rules="required" v-slot="{ isError }">
              <Input
                v-model="form.displayName"
                label="Name"
                placeholder="Name"
                :is-error="isError"
                :is-disabled="!isAvailable"
              />
            </FormItem>
            <FormItem>
              <ImageUploader
                v-model="form.photoUrl"
                scope="profiles"
                label="Photo"
                :max-size="500"
                :is-disabled="!isAvailable"
                is-small
              />
            </FormItem>
          </div>
          <div class="edit-user-page__actions mt-auto">
            <Button
              v-if="isAvailable"
              :is-disabled="invalid || !changed || isDeleting"
              :is-block="['xs', 'sm'].includes($mq)"
              :is-loading="isSubmitting"
              @click="handleSubmit"
            >
              Save
            </Button>
            <Guard
              permission="users.delete"
              v-slot="{ isAvailable: isDeleteAvailable }"
            >
              <Button
                v-if="isDeleteAvailable"
                is-outlined
                variant="danger"
                :is-disabled="isSubmitting"
                :is-block="['xs', 'sm'].includes($mq)"
                :is-loading="isDeleting"
                @click="remove"
              >
                Remove user from a venue
              </Button>
            </Guard>
          </div>
        </Form>
      </template>
    </div>
  </Guard>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { auth } from "@/plugins/firebase";
import { mapActions, mapGetters, mapState } from "vuex";
import { ROLE_NAMES_MAP } from "@/helpers/const";
import { roleOptions } from "@/helpers/mocks";
import dialog from "@/plugins/dialog";
import UsersService from "@/api/services/UsersService";
import Guard from "@/components/common/Guard";
import ImageUploader from "@/components/common/ImageUploader";
import { getTimeDifference } from "@/helpers/utils";
import moment from "moment";

export default {
  name: "EditUserPage",
  components: { ImageUploader, Guard, BackTitle },
  data() {
    return {
      isLoading: false,
      roleOptions,
      isDeleting: false,
      isSubmitting: false,
      form: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.users.editedItem,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    isYou() {
      return this.user && auth.currentUser.uid === this.user.id;
    },
    title() {
      if (this.isLoading) {
        return "";
      }
      if (this.isYou) {
        return `${ROLE_NAMES_MAP[this.user.role]} (You)`;
      }
      if (this.checkPermission("users.edit")) {
        return "Edit user";
      }
      return "User details";
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchUserById(this.$route.params.id);
      const { displayName, photoUrl, email, role } = this.user;
      this.form = {
        displayName,
        photoUrl,
        email,
        role,
      };
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchUserById: "users/fetchUserById",
    }),
    goBack() {
      this.$router.push({
        name: "Users",
      });
    },
    getRoleName(role) {
      return ROLE_NAMES_MAP[role];
    },
    getLastTimeString(timestamp) {
      return timestamp
        ? getTimeDifference({
            current: moment.utc().valueOf(),
            previous: timestamp,
            recentMinutes: 60,
            recentString: "recently",
          })
        : "never";
    },
    async handleSubmit() {
      const isRoleChanged = this.form.role !== this.user.role;
      const confirmed =
        !isRoleChanged ||
        (await dialog.confirm({
          title: "Are you sure?",
          message: `The role of this user will be changed to ${this.form.role}`,
          okText: "Save and change role",
          cancelText: "Cancel",
        }));

      if (confirmed) {
        try {
          this.isSubmitting = true;
          await UsersService.updateUser(this.$route.params.id, this.form);
          if (isRoleChanged) {
            await dialog.alert({
              title: `The user's role has been successfully changed to ${this.form.role}`,
              okText: "Done",
            });
          }
          await this.goBack();
        } finally {
          this.isSubmitting = false;
        }
      }
    },
    async remove() {
      const confirmed = await dialog.confirm({
        title: "Are you sure?",
        message: "You will be able to invite user again",
        okText: "Remove",
        cancelText: "Cancel",
      });

      if (confirmed) {
        try {
          this.isDeleting = true;
          await UsersService.deleteUser(this.$route.params.id);
          await this.goBack();
        } finally {
          this.isDeleting = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-user-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    border-radius: 8px;
    box-shadow: $box-shadow-small;
    border: 2px solid $secondary-400;
    margin-bottom: 32px;

    @media (min-width: $media-laptop) {
      margin-bottom: 48px;
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      line-height: 24px;
      color: $secondary-500;

      :last-child {
        font-weight: 500;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
</style>
